<template>
    <List
        v-if="website"
        resource="websiteAudits"
        :fields="logHistoryFields"
        ref="list_log_history"
        :pageSize="10"
        :search="true"
        :requestParams="{ communities_id: this.website.communities_id, websites_id: this.website.id }"
        :sort-order="[{direction: 'desc', sortField: 'created_at'}]"
        search-placeholder="Search for a user by name or email"
    >
    </List>

</template>

<script>
import AuthMixin from '@/components/auth/AuthMixin'
import ModalNavigation from '@/mixins/ModalNavigation'
import NotifyMixin from '@/mixins/NotifyMixin'
import List from '@/components/cms/List'
import {mapGetters} from "vuex";

export default {
    name: 'CreateResponse',
    components: {List},
    mixins: [AuthMixin, ModalNavigation, NotifyMixin],
    data() {
        return {
            website: null,
            logHistoryFields: [
                {
                    name: 'user_name',
                    title: 'user',
                    sortField: 'first_name'
                },
                {
                    name: 'user_email',
                    title: 'email',
                    sortField: 'email'
                },
                {
                    name: 'user_type',
                    title: 'user type',
                    sortField: 'role'
                },
                {
                    name: 'event',
                    title: 'event',
                    sortField: 'event'
                },
                {
                    name: 'auditable_type',
                    title: 'event type',
                    sortField: 'auditable_type'
                },
                {
                    name: 'created_at_en',
                    title: 'date',
                    sortField: 'created_at'
                }
            ],
        }
    },
    computed: {
        ...mapGetters({
            getWebsiteInfo: 'website_info/getWebsiteInfo'
        }),
    },
    async mounted() {
        this.website = this.getWebsiteInfo;
    }
}
</script>

