<template>
    <div class="list-container">
        <ListFilter
            ref="filter"
            :class="{'mb-8': hasFilters || search}"
            :filter="filter"
            :hasFilters="hasFilters"
            :hasQuickFilters="hasQuickFilters"
            :query="query"
            :search="search"
            :search-placeholder="searchPlaceholder"
            :filter-classes="filterClasses"
            :initial-values="initialFilter"
            :apply-filter="values => applyFilter(filterMapper(values))"
            :apply-search="applySearch"
            :clear-search="clearSearch"
        >
            <template v-slot:filters="props">
                <slot name="filters" :reset="props.reset" :filter="filter"/>
            </template>
            <template v-slot:quickfilter>
                <slot name="quickfilter" :applyFilter="applyFilter"></slot>
            </template>
            <template v-slot:loader>
                <loader :loading="loading" :backdrop="true"/>
            </template>
        </ListFilter>
        <vuetable
            class="list"
            ref="vuetable"
            :api-mode="false"
            :fields="fields"
            :data-manager="dataManager"
            :css="css.table"
            :track-by="trackBy"
            :sort-order="isSelected ? [] : sortOrder"
            pagination-path="pagination"
            @vuetable:pagination-data="onPaginationData"
            :row-class="rowClass"
            @vuetable:cell-clicked="returnRowData"
        >
            <template v-slot:status="props">
                <div @click="handleDelete(props)" v-if="hasIcons">
                    <slot name="status" :status="props.rowData.status"/>
                </div>
            </template>
        </vuetable>
        <vuetable-pagination
            ref="pagination"
            :css="css.pagination"
            @vuetable-pagination:change-page="onChangePage"
        />
    </div>
</template>

<script>
import {Vuetable, VuetablePagination} from 'vue3-vuetable';
import {throttle} from 'lodash-es';
import Loader from '@/components/ui/Loader';
import NotifyMixin from "@/mixins/NotifyMixin";
import AuthMixin from '@/components/auth/AuthMixin';
import ListFilter from "@/components/auth/list/ListFilter";

const css = {
    table: {
        tableClass: 'table-auto w-full table',
        tableBodyClass: '',
        tableHeaderClass: 'px-4 py-2',
        tableWrapper: 'overflow-x-auto flex-1',
        loadingClass: 'loading',
        ascendingIcon: 'blue chevron up icon',
        descendingIcon: 'blue chevron down icon',
        ascendingClass: 'sorted-asc',
        descendingClass: 'sorted-desc',
        sortableIcon: 'grey sort icon',
        handleIcon: 'grey sidebar icon',
        detailRowClass: 'bg-blue-100',
    },
    pagination: {
        wrapperClass: 'flex justify-center py-4',
        activeClass: 'active',
        disabledClass: '',
        pageClass: 'btn-paging',
        linkClass: 'btn-paging',
        paginationClass: '',
        paginationInfoClass: 'pagination-info',
        dropdownClass: '',
        icons: {
            first: '',
            prev: '',
            next: '',
            last: '',
        }
    },
};
export default {
    name: 'List',
    data: function () {
        return {
            query: '',
            filter: this.filterMapper(this.initialFilter),
            loading: false,
            selectedIndex: null,
            isDisabled: false
        };
    },
    computed: {
        hasFilters() {
            return !!this.$slots.filters;
        },
        hasQuickFilters() {
            return !!this.$slots['quickfilter'];
        },
        hasIcons() {
            return !!this.$slots.status;
        },
        isEmpty() {
            if (!this.search) return false;
            if (!this.query) return false;
            if (this.query.trim().length !== 0) return false;
            return this.query.split(' ').length - 1 >= 2 && !this.$props.searchButton
        }
    },
    props: {
        css: {
            type: Object,
            default() {
                return css;
            },
        },
        fields: {
            type: Array,
            required: true,
        },
        resource: {
            type: String,
            required: true,
        },
        sortOrder: {
            type: Array
        },
        trackBy: {
            type: String,
            default: 'id'
        },
        pageSize: {
            type: Number,
            default: 10,
        },
        requestParams: {
            type: Object,
            default() {
                return {};
            }
        },
        search: {
            type: Boolean,
            default: false
        },
        searchPlaceholder: {
            type: String,
            default: 'Start typing to search'
        },
        searchButton: {
            type: Boolean,
            default: false
        },
        clickable: {
            type: Boolean,
            default: false
        },
        isSelected: {
            type: Boolean,
            default: false
        },
        isCommunityRequired: {
            type: Boolean,
            default: false
        },
        onReload: {
            type: Function,
            default: function () {
            }
        },
        filterMapper: {
            type: Function,
            default: function (values) {
                return values;
            }
        },
        filterClasses: {
            type: Object,
            default: () => ({}),
        },
        initialFilter: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    emits: ['deleteRow', 'rowData'],
    components: {
        ListFilter,
        Loader,
        Vuetable,
        VuetablePagination,
    },
    mixins: [NotifyMixin, AuthMixin],
    watch: {
        onReload() {
            this.reload();
        },
        community: function () {
            if (this.community?.id && this.$props.isCommunityRequired) {
                this.dataManager();
                this.$refs.vuetable.refresh();
            } else if (!this.$props.isCommunityRequired) {
                this.dataManager();
                this.$refs.vuetable.refresh();
            } else {
                if (this.$refs.vuetable) {
                    this.$refs.vuetable.resetData();
                    this.$refs.vuetable.refresh();
                }
            }
        },
        query() {
            this.doSearch();
        },
        filter() {
            this.doSearch();
        },
    },
    methods: {
        handleDelete(payload) {
            if (this.isSelected) {
                return;
            }
            const {rowIndex, rowData} = payload;
            const paginationData = {
                data: rowData,
                index: rowIndex
            }
            this.selectedIndex = rowIndex;
            this.$emit('deleteRow', paginationData)
        },
        applySearch(value) {
            this.query = value;
        },
        clearSearch() {
            this.query = '';
        },
        applyFilter(values) {
            this.filter = values;
            this.$refs.vuetable?.changePage(1);
            this.$refs.vuetable?.resetData();
        },
        doSearch: throttle(function () {
            if (this.query.length >= 1 || this.query.length === 0) {
                this.reload();
            }
        }, 500, {trailing: true}),
        reload() {
            if (this.$refs.vuetable) {
                this.$refs.vuetable.resetData();
                this.$refs.vuetable.refresh();
            }
        },
        onSearch() {
            if (!this.$props.searchButton) {
                this.doSearch();
            }
        },
        onPaginationData(paginationData) {
            this.$refs.pagination?.setPaginationData(paginationData);
        },
        onChangePage(page) {
            if (this.isSelected) {
                return;
            }
            this.$refs.vuetable.changePage(page);
        },
        addHighlightTag(row, query) {
            if (!query) return row;
            const queryLowC = query.toLowerCase();
            return Object.entries(row).reduce((prevVal, currentVal) => {
                const [key, value] = currentVal;
                if (typeof value === 'string') {
                    return {...prevVal, [key]: value.toLowerCase().replace(queryLowC, `<mark>${queryLowC}</mark>`)}
                }
                if (Array.isArray(value)) {
                    return {
                        ...prevVal,
                        [key]: value.map(val => val.toLowerCase().replace(queryLowC, `<mark>${queryLowC}</mark>`))
                    }
                }
                return {...prevVal, ...currentVal}
            }, {});
        },
        dataManager(sortOrder = [], pagination = {current_page: 1}) {
            if (!this.community?.id && this.$props.isCommunityRequired) return;
            const {pageSize: size, query} = this;
            const sort = sortOrder[0] ? sortOrder[0] : {direction: '', sortField: ''};

            this.loading = true;
            return this.$cmsDataProvider.getList(
                this.resource,
                {
                    page: pagination.current_page,
                    per_page: size,
                    search: query,
                    order_dir: sort.direction,
                    order_by: sort.sortField,
                    ...this.requestParams,
                })
                .then((res) => {
                    const newPagination = this.$refs.vuetable?.makePagination(res.meta.total, this.pageSize, pagination.current_page);
                    const data = res?.data || res;
                    return {
                        pagination: newPagination,
                        data: data.map(row => this.addHighlightTag(row, query)),
                        sort
                    };
                })
                .catch(error => {
                    this.notifyError(error.message);
                    return {
                        pagination: this.$refs.vuetable?.tablePagination,
                        data: this.$refs.vuetable?.tableData
                    };
                })
                .finally(() => this.loading = false);
        },
        handleScrollContainer(e) {
            if (this.$refs.vuetable.tablePagination && e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight <= 2) {
                this.onChangePage('next');
            }
        },
        returnRowData(paginationData) {
            if (this.isSelected) {
                return;
            }
            this.selectedIndex = paginationData.index;
            this.$emit('rowData', paginationData)
        },
        rowClass(_, index) {
            if (!this.isSelected) {
                return ''
            }
            return this.selectedIndex === index ? 'row--active' : 'row--disabled';
        },
    },
    mounted() {
        this.$refs.vuetable.changePage(1);
    },
};
</script>
<style scoped>
.list-container {
    @apply relative flex flex-col h-full;
}

.list-container :deep(.sort-icon) {
    float: none !important;
    display: inline-block;
    margin-left: 0.5rem;
}

.list-container :deep(.vuetable) {
    @apply text-black;
}

.list-container :deep(.vuetable th) {
    @apply text-sm font-400 border-b;
}

.list-container :deep(.vuetable-empty-result) {
    @apply bg-blue-50 text-blue-700 font-500 text-sm text-center p-6;
    box-shadow: inset 0 0 0 1px #C4DEF0;
}

/* remove table hover effect */
.list-container :deep(.table-row-nonclickable:hover) {
    @apply bg-white;
}

.list-container :deep(.table-row-nonclickable:nth-child(even):hover) {
    @apply bg-gray-100;
}

/* detail row */
.list-container :deep(.vuetable-detail-row),
.list-container :deep(.table-row-detail-open),
.list-container :deep(.table-row.table-row-detail-open:hover) {
    @apply bg-blue-50;
}

.list-container :deep(.vuetable-detail-row > td) {
    box-shadow: inset 0 -1px 0 0 #C4DEF0, inset -1px 0 0 0 #C4DEF0, inset 1px 0 0 0 #C4DEF0;
}

.list-container :deep(.table-row-detail-open > td) {
    box-shadow: inset 0 1px 0 0 #C4DEF0;
}

.list-container :deep(.table-row-detail-open > td):first-child {
    box-shadow: inset 1px 1px 0 0 #C4DEF0;
}

.list-container :deep(.table-row-detail-open > td):last-child {
    box-shadow: inset -1px 1px 0 0 #C4DEF0;
}
</style>
